import React, { useEffect, useState } from "react"
import { PageProps, Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMdx: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          updated: string
          draft: boolean
          private: boolean
          starred: boolean
          importance: number
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

type OrderedBy = "importance" | "date"

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const [orderedBy, setOrderedBy] = useSessionStorage<OrderedBy>(
    "notes-ordered-by",
    "importance",
  )

  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges
  posts.forEach(({ node }) => {
    if (node.frontmatter.updated) {
      node.frontmatter.date = node.frontmatter.updated
    }
  })

  posts.sort((a, b) => {
    const fieldA = a.node.frontmatter[orderedBy] || 0
    const fieldB = b.node.frontmatter[orderedBy] || 0

    if (fieldA > fieldB) {
      return -1
    }

    if (fieldA < fieldB) {
      return 1
    }

    // Everything else being equal, starred notes should come first.
    if (a.node.frontmatter.starred && !b.node.frontmatter.starred) {
      return -1
    }

    return 0
  })

  return (
    <Layout>
      <SEO title={siteTitle} />
      <Bio />
      <h2 id="notes">Notes</h2>{" "}
      <span style={{ fontSize: ".8rem" }}>
        ordered by:{" "}
        <select
          name="ordered-by"
          id="ordered-by"
          onChange={e => {
            setOrderedBy(e.target.value as OrderedBy)
          }}
          value={orderedBy}
        >
          <option value="date">date</option>
          <option value="importance">importance</option>
        </select>
      </span>
      <div className={"notes-index"}>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug

          return (
            <article key={node.fields.slug}>
              <header>
                <Link to={node.fields.slug}>{title}</Link>
                &nbsp;
                <small className="note-subheading">
                  {node.frontmatter.starred && (
                    <span className={"starred"}>{"\u2605"}&nbsp;</span>
                  )}
                  {node.frontmatter.date}
                  {node.frontmatter.draft && " (draft)"}
                  {node.frontmatter.private && " (private)"}
                </small>
              </header>
            </article>
          )
        })}
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { published: { eq: true } }
      sort: {
        fields: [frontmatter___draft, frontmatter___date]
        order: [DESC, DESC]
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY/MM")
            updated(formatString: "YYYY/MM")
            title
            draft
            private
            starred
            importance
          }
        }
      }
    }
  }
`

const isBrowser = typeof window !== "undefined"

function getSessionStorageOrDefault(key: string, defaultValue: any) {
  if (!isBrowser) {
    return defaultValue
  }

  const stored = sessionStorage.getItem(key)
  if (!stored) {
    return defaultValue
  }
  return JSON.parse(stored)
}

export function useSessionStorage<T>(
  key: string,
  defaultValue: T,
): [T, (arg0: T) => void] {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(getSessionStorageOrDefault(key, defaultValue))
  }, [getSessionStorageOrDefault])

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

import React from "react"

const Bio = () => {
  let ninja = (
    <span role="img" aria-label="stealth">
      🥷
    </span>
  )

  return (
    <div id="main-header">
      <h1>Hi there! I'm Nicolae Vartolomei.</h1>
      <p>
        I'm an engineer, currently busy building something new {ninja}. In the
        past 15 years I've built infra/platform/products at Cloudflare and at a
        few other startups. <br className="hide-on-small-screen" />
        You can find me on{" "}
        <a
          href="https://twitter.com/nvartolomei"
          target="_blank"
          rel="noreferrer"
        >
          Twitter
        </a>
        ,{" "}
        <a
          href="https://github.com/nvartolomei"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </a>
        ,{" "}
        <a
          href="https://www.linkedin.com/in/nvartolomei/"
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn
        </a>
        , or get in touch{" "}
        <a href="mailto:nv@nvartolomei.com">nv@nvartolomei.com</a>.
      </p>
    </div>
  )
}

export default Bio
